import React, { Component } from "react"
import Image from "../../images/homepage-image.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown } from "@fortawesome/free-solid-svg-icons"
// import { Link } from 'gatsby';
import { Link } from "react-scroll"
import Reveal from "react-reveal/Reveal"
import styles from "./hero.module.scss";
import Zoom from 'react-reveal/Zoom'
import Fade from 'react-reveal/Fade';


class Hero extends Component {

  render() {
    return (
      <header className={styles.hero}>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.text}>
              <Fade>
                <h1>
                  Innovative solutions for outstanding growth.
                </h1>
                <p>
                  We are committed to building innovative solutions to complex
                  problems. We build business transforming solutions using
                  technology.
                </p>
              </Fade>
              <Fade>
                <a
                  href="/contact/"
                  className={styles.button}
                >
                  Build Now
                </a>
              </Fade>
            </div>
            <Zoom>
              <div className={styles.image}>
                <img src={Image} alt="Homepage" />
              </div>
            </Zoom>
          </div>
          <Link
            to="about"
            className={styles.iconContainer}
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <Reveal effect="bounce">
              <FontAwesomeIcon className={styles.icon} icon={faArrowDown} />
            </Reveal>
          </Link>
        </div>
      </header>
    )
  }
}

export default Hero
