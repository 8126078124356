import React from "react"
import styles from "./timeline.module.scss"
import Fade from "react-reveal/Fade"
import Data from '../../images/icons/data.svg'
import SourceCode from '../../images/icons/source-code.svg'
import Training from '../../images/icons/training.svg'
import Vas from '../../images/icons/vas.svg'



const TimelineItem = (props) => {
  return (
    <div className={styles.item}>
      <div className={styles.icon}>
        <img src={props.icon} alt={props.title} />
      </div>
      <div className={styles.content}>
        <h4>{props.title}</h4>
        <p>{props.text}</p>
      </div>
    </div>
  )
}

const Timeline = () => {
  return (
    <div className={styles.itemContainer}>
        <TimelineItem 
          title="Software Development" 
          text = "At iQube Labs, we develop several solutions for a wide variety of clients from different sectors in Nigeria and abroad. We offer a robust suite of digital products and services, including web and mobile software development as well as business solutions that push boundaries and create immediate marketing impact."
          icon={SourceCode}
        />
         <TimelineItem 
          title="Data Science Consultancy" 
          text = "Also, we analyse and visualise data for organizations towards building sustainable models that will improve business performance. We do not stop there, we use data and insights to build algorithms that power digital products that deliver business success."
          icon={Data}
        />
         <TimelineItem 
          title = "iQube Tech Training"
          text = "We are committed to engaging and building talents to create the blended learning structure for the various courses in tech. We now have tech careers accelerator programs where trainees get immersive learning plus internships over 5 months"
          icon={Training}
        />
         <TimelineItem 
          title="iVAS (intelligent Value Added Service)" 
          text = "iQube Labs offers and builds technological solutions for telecoms businesses and telecoms related services. Globally, Value Added Services denotes a sizeable revenue opportunity for the service providers owing to the fact that it pulls more users to access the utility, entertainment and transactional services on the go. We recognise the need for VAS in this fast-paced economy, hence, the reason for this service for Telecom firms and others."
          icon={Vas}
        />
    </div>
  )
}

export default Timeline
