import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import Reveal from "react-reveal/Reveal"


import counterStyles from './counter.module.scss';

const Counter = () => {
    const [viewPortEntered, setViewPortEntered] = useState(false);
    return(
        <div className={counterStyles.counterContainerFluid}>
            <Reveal effect={counterStyles.fadeIn}>
                <div className={counterStyles.head} style={{ transitionDelay: "500ms" }}>
                    <h3>Our <span>Numbers</span></h3>
                    <h4>We are an organisation that is motivated by challenges and we love to use technologies to make a positive impact
                    </h4>
                </div>
            </Reveal>
            <div className={counterStyles.counterContainer}>
                <div className={counterStyles.counterItem}>
                    <CountUp  start={viewPortEntered ? null : 0} end={23} duration={3} suffix="+">
                        {({ countUpRef }) => {
                            return (
                            <VisibilitySensor
                                active={!viewPortEntered}
                                onChange={isVisible => {
                                if (isVisible){
                                    setViewPortEntered(true);
                                }
                                }}
                                delayedCall
                            >   

                                <div className={counterStyles.number}>
                                    <div className={counterStyles.borderTop}></div>
                                    <h1 ref={countUpRef} />
                                    <p>Countries Reached</p>
                                </div>  
                            </VisibilitySensor>
                            );
                        }}
                    </CountUp>
                </div>
                <div className={counterStyles.counterItem}>
                    <CountUp  start={viewPortEntered ? null : 0} end={150} duration={3} suffix="+">
                        {({ countUpRef }) => {
                            return (
                            <VisibilitySensor
                                active={!viewPortEntered}
                                onChange={isVisible => {
                                if (isVisible){
                                    setViewPortEntered(true);
                                }
                                }}
                                delayedCall
                            >   

                                <div className={counterStyles.number}>
                                    <div className={counterStyles.borderTop}></div>
                                    <h1 ref={countUpRef} />
                                    <p>Clients</p>
                                </div>  
                            </VisibilitySensor>
                            );
                        }}
                    </CountUp>
                </div>
                <div className={counterStyles.counterItem}>
                    <CountUp  start={viewPortEntered ? null : 0} end={50} duration={3} suffix="+">
                        {({ countUpRef }) => {
                            return (
                            <VisibilitySensor
                                active={!viewPortEntered}
                                onChange={isVisible => {
                                if (isVisible){
                                    setViewPortEntered(true);
                                }
                                }}
                                delayedCall
                            >   

                                <div className={counterStyles.number}>
                                    <div className={counterStyles.borderTop}></div>
                                    <h1 ref={countUpRef} />
                                    <p>People Trained</p>
                                </div>  
                            </VisibilitySensor>
                            );
                        }}
                    </CountUp>
                </div>
                <div className={counterStyles.counterItem}>
                    <CountUp  start={viewPortEntered ? null : 0} end={22} duration={2} suffix="+" decimal="," decimals={3}>
                        {({ countUpRef }) => {
                            return (
                            <VisibilitySensor
                                active={!viewPortEntered}
                                onChange={isVisible => {
                                if (isVisible){
                                    setViewPortEntered(true);
                                }
                                }}
                                delayedCall
                            >   

                                <div className={counterStyles.number}>
                                    <div className={counterStyles.borderTop}></div>
                                    <h1 className={counterStyles.big} ref={countUpRef} />
                                    <p>Unique subscriptions for our iVAS product</p>
                                </div>  
                            </VisibilitySensor>
                            );
                        }}
                    </CountUp>
                </div>
            </div>
        </div>
    )
}

export default Counter;