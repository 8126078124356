import React from "react"
import Layout from "../components/layout"
import Hero from "../components/Hero"
import HomeAbout from "../components/HomeAbout/"
import HomeServices from "../components/HomeServices/"
// import Trusted from "../components/Trusted/Trusted";
import Counter from "../components/Counter/counter"
import SEO from "../components/seo";
import Brands from "../components/Brands";

const Homepage = () => {
  return (
    <Layout>
      <SEO 
      />
      <Hero />
      <HomeAbout />
      <HomeServices />
      {/* <Trusted /> */}
      <Brands />
      <Counter />
    </Layout>
  )
}

export default Homepage
