import React from 'react'
import { Link } from 'gatsby'
// import classNames from 'classnames'
import styles from "./viewlink.module.scss"



const ViewLink = (props) => {
  return (
   <Link to={props.to} className={styles.link}>
    {props.text}
   </Link>
  )
}

export default ViewLink