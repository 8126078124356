import React from "react";
import styles from "./trusted.module.scss";
import Reveal from "react-reveal/Reveal";
import { StaticQuery, graphql } from "gatsby"
import Fade from "react-reveal/Fade"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";


function SampleNextArrow(props) {
  const {  onClick } = props;
  return (
    <div
      className={styles.iconRight}
      onClick={onClick}
    >
      <FontAwesomeIcon 
        className={styles.font}
      icon={faAngleRight} />
    </div>
  );
}


function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
     <div
      className={styles.iconLeft}
      onClick={onClick}
    >
      <FontAwesomeIcon 
        className={styles.font}
        icon={faAngleLeft} />
    </div>
  );
}

var settings = {
  infinite: true,
  speed: 400,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  autoplay: true,
  lazyLoad: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]

}

const BrandImages = () => {
  return (
    <StaticQuery
      query={graphql`
        query MyQuery {
          allContentfulClients {
            edges {
              node {
                title
                logo {
                  file {
                    url
                    fileName
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div className={styles.imgContainer}>
          <Slider {...settings}>
            {data.allContentfulClients.edges.map(images => (
              <Fade cascade>
                <div className={styles.image}>
                  <img
                    src={images.node.logo.file.url}
                    alt={images.node.title}
                  />
                </div>
              </Fade>
            ))}
          </Slider>
        </div>
      )}
    />
  )
}

const Brands = () => {
  return (
    <div className={styles.container}>
      <div className={styles.head}>
        <Reveal effect={styles.fadeIn}>
          <h3>These brands trust us </h3>
        </Reveal>
      </div>
      <div className={styles.image}>
        <BrandImages />
      </div>
    </div>
  )
}

export default Brands
