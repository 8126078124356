import React from 'react';
import OrangeBg from '../../images/home-about-bg.png';
import Reveal from 'react-reveal/Reveal';
import Fade from 'react-reveal/Fade';
import styles from './homeAbout.module.scss'
import ViewLink from '../ViewLink/ViewLink'

const HomeAbout = () => (
  <div className={styles.about} id='about'>
    <div className={styles.container}>
      <div className={styles.head}>
        <Reveal effect={styles.fadeIn}>
          <h3>
            Data. Innovations. People.
          </h3>
        </Reveal>
      </div>

      <div className={styles.content}> 
        <Fade left>
           <div className={styles.text}>
            <h4>
              We are an organisation driven by data to create relevant and innovative solutions which directly impact the lives of people.
            </h4>
            <ViewLink 
              text= "Learn more about us"
              to="/about"
            />
          </div>
        </Fade>
        <Reveal effect={styles.fadeIn}>
          <div className={styles.image}>
            <img src={OrangeBg} alt="Orange background"/>
            <div className={styles.overlay}>
              <h5>
                Been Innovating Since
              </h5>
              <p>
                2011
              </p>
            </div>
          </div>
        </Reveal>
      </div>
    </div>
  </div>
)

export default HomeAbout