import React from "react"
import styles from "./homeservices.module.scss"
import Reveal from "react-reveal/Reveal"
import Timeline from "../Timeline"

const HomeServices = () => {
  return (
    <div className={styles.services}>
      <div className={styles.container}>
        <Reveal effect={styles.fadeIn}>
          <div className={styles.head} style={{ transitionDelay: "500ms" }}>
            <h3>Our <span>Solutions</span></h3>
            <h4>
              We take a collaborative approach to deliver the best solutions
              that meet the needs of our clients. Working closely with our
              clients by understanding their needs, we prioritise quality,
              budgets and delivery time.
            </h4>
            <p>We use one or more of our competencies to build solutions.</p>
          </div>
        </Reveal>
        <Timeline />
      </div>
    </div>
  )
}

export default HomeServices
